const device = [
  {
    name: 'Device',
    path: 'device',
    components: {
      default: () => import(/* webpackChunkName: "DeviceBtRouterView" */ '@/components/BtRouterView'),
      navigation: () => import(/* webpackChunkName: "DeviceNavigation" */ '@/views/device/Navigation')
    },
    children: [
      {
        name: 'DeviceDashboard',
        path: ':mac/dashboard',
        meta: {
          auth: true,
          permission: ['ROLE_DEVICE_VIEW', 'ROLE_DEVICE_FULL_MANAGEMENT']
        },
        component: () => import(/* webpackChunkName: "DeviceDashboard" */ '@/views/device/Dashboard')
      },
      {
        name: 'DeviceOwners',
        path: ':mac/owners',
        meta: {
          auth: true,
          permission: ['ROLE_DEVICE_VIEW', 'ROLE_DEVICE_FULL_MANAGEMENT']
        },
        component: () => import(/* webpackChunkName: "DeviceOwners" */ '@/views/device/Owners')
      },
      {
        name: 'DeviceExternalDevices',
        path: ':mac/external-devices',
        meta: {
          auth: true,
          permission: ['ROLE_DEVICE_VIEW', 'ROLE_DEVICE_FULL_MANAGEMENT']
        },
        component: () => import(/* webpackChunkName: "DeviceExternalDevices" */ '@/views/device/ExternalDevices')
      },
      {
        name: 'DeviceSettings',
        path: ':mac/settings',
        meta: {
          auth: true,
          permission: ['ROLE_DEVICE_VIEW', 'ROLE_DEVICE_FULL_MANAGEMENT']
        },
        component: () => import(/* webpackChunkName: "DeviceSettings" */ '@/views/device/DeviceSettings.vue')
      }
    ]
  }
]

export default device
